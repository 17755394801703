function Contact() {
    return (
        <>
            <div className={"paragraph paragraph--content paragraph--small"}>
                <h1>CONTACT</h1>
                <h3>BOOKING</h3>
                <a href="mailto:booking@fuckingfoos.nl">booking@fuckingfoos.nl</a>
            </div>
      </>
    );
}

export default Contact;
