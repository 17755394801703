import MemberCard from '../Components/MemberCard';
function About() {
    return (
        <>
            <div className={"paragraph paragraph--content paragraph--small"}>
            <h1>ABOUT US</h1>
                <MemberCard 
                    name='PAUL'
                    description='<p>Gitarist Paul Hamwijk is opgegroeid in het Achterhoekse Lochem, alwaar hij al op zeer jonge leeftijd begon met gitaarspelen. Zijn moeder beloofde hem een gitaar, mits hij minimaal 2 jaar klassieke les zou nemen. Met frisse tegenzin zijn die 2 jaar doorlopen; Paul speelde liever Jimi Hendrix en Led Zeppelin dan Für Elise en Dust In The Wind. Dat de klassieke opleiding later zijn vruchten zou afwerpen was op dat moment totaal irrelevant...</p>
                    <p>Na in verschillende bandjes te hebben gespeeld is hij via Utrecht uiteindelijk geland in Indo rock stad van Nederland: Den Haag, zijn huidige woonplaats, waar hij zijn liefde en passie voor vintage gitaren en versterkers heeft omgezet in een van de coolste internationale vintage gitaren shops van Nederland e.o., <a href="https://www.bees-in-a-jar.com">bees-in-a-jar.com</a> Ook heeft hij de afgelopen jaren in meerdere rock bands gespeeld en op verschillende podia gestaan in binnen en buitenland waarbij zijn voorliefde voor classic rock altijd de basis is geweest. Een Foo Fighters tribute band is daarbij altijd zijn droom geweest. Een droom die nu eindelijk is verwezenlijkt.</p>'
                />
       
                <MemberCard name='COEN'/>
          
                <MemberCard name='SAMI'/>
         
                <MemberCard 
                    name='WILMER'
                    description='<p>Op jonge leeftijd heeft hij al affiniteit met zingen. Op zn 18e levensjaar kocht hij een gitaar en leerde zichzelf gitaar spelen en songwriten.</p>
                    <p>In het begin storte hij zich vooral op powerchord riffs van greenday, poppy tunes van James Bay, of de juicy licks van john mayer. Maar al snel kregen iets zwaardere bands als Sum41, Incubus, Alter Bridge en Bullet for my Valentine de voorkeur, om uiteindelijk de diepte in te gaan met metalcore bands, zoals Motionless in White, Landmvrks, Architects en We Came As Romans.</p>
                    <p>Hier en daar een uitstap naar genres als rap, r&b of zelfs jazz, blues en klassiek, maakte hem niks uit.</p>
                    <p>Om er elke keer weer achter te komen dat er niets gaat boven keiharde oorverpletterende Rock & Roll!</p>'
                />
             
                <MemberCard name='JENS'/>
            </div>
      </>
    );
}

export default About;
