import PAUL from "../assets/img/paul-portret.jpeg";
import PAULLIVE from "../assets/img/PAUL.jpeg";
import SAMI from "../assets/img/sami-portret.jpeg";
import WILMER from "../assets/img/wilmer-portret.jpeg";
import COEN from "../assets/img/coen-portret.jpeg";
import JENS from "../assets/img/jens-portret.jpeg";

import React, {useState, useRef} from 'react';

import { CSSTransition } from 'react-transition-group';
function MemberCard({name, description}) {

    const [isShown, setIsShown] = useState(false);
    const nodeRef = useRef(null);

    return (
        <div className="member-card" >
        
            <div className={'liveImage'} onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>
            
                {name === "PAUL" && <img src={PAUL} alt="Paul speelt live op een gitaar" />}
                {name === "SAMI" && <img src={SAMI} alt="Sami speelt live op een gitaar" />}
                {name === "WILMER" && <img src={WILMER} alt="Wilmer speelt live op een gitaar" />}
                {name === "COEN" && <img src={COEN} alt="Wilmer speelt live op een gitaar" />}
                {name === "JENS" && <img src={JENS} alt="Wilmer speelt live op een gitaar" />}
            
                <CSSTransition nodeRef={nodeRef} in={isShown} timeout={400} classNames="my-node" className={'live'}>
                    <div ref={nodeRef}>
                        <img src={PAULLIVE} alt="Paul speelt live op een gitaar" />
                    </div>
                </CSSTransition>
                
                <div class={"hover-effect"} />
            </div>
           <h3>{name}</h3>
           <div className={'divider'}></div>
           <div dangerouslySetInnerHTML={{__html: description}} />
        </div>
    );
}
export default MemberCard;