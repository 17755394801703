import logo from './assets/img/ff-logo.jpg';
import Home from './Pages/Home';
import Venue from './Pages/Venue';
import Contact from './Pages/Contact';
import About from './Pages/About';
import MainMenu from './Components/Menu/Main';
import MobileMenu from './Components/Menu/Mobile';
import Footer from './Components/Footer';
import React, {useEffect} from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';

function App() {
  useEffect(() => {
    document.title = "FVCKING FOOS"
  }, []);

  return (
    <div className="App">
      <header className="App-header content-container">
        <div className={"App-header--left hide-on-mobile"}>
          <div></div>
          <div></div>
        </div>
        <a href="/" className="App-logo"><img src={logo}  alt="logo" /></a>
        <div className={"App-header--right"}>
          <div className={"hide-on-mobile"}><span>THE</span> F#CKING FOOS</div>
          <MainMenu />
          <MobileMenu />
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/technical" element={<Venue/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/venue" element={<Venue/>} />
        <Route path="/about" element={<About/>} />
      </Routes>

      <div className={"background-pinstripe"}>
        <div className={"container"}>
          <div className={"white"}>
            <div className={"black"}></div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default App;
