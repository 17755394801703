function FooterMenu() {
    return (
        <>
            <div className={"menu"}>
                <a href="/venue">VENUE INFO</a> - <a href="/contact">CONTACT</a>
            </div>
        </>
    );
}

export default FooterMenu;