import { HashLink as Link } from 'react-router-hash-link';

function MainMenu() {
    return (
        <>
            <div className={"menu hide-on-mobile"}>
                <Link to="/#tour">TOUR</Link> - <Link to="/#media">MEDIA</Link> - <Link to="/contact">CONTACT</Link>
            </div>
        </>
    );
}

export default MainMenu;