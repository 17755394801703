function Venue() {
    return (
        <>
            <h1>VENUE INFORMATION</h1>
            <div className={"paragraph paragraph--content paragraph--small"}>
                <h2>DOWNLOADS</h2>

                <h3>TECHNISCHE GEGEVENS</h3>
                <a href="/technical-rider.pdf" download target="_blank">Technische rider</a><br />

            </div>

            <div className={"divider"}/>

            <div className={"paragraph paragraph--content paragraph--small"}>
                <h2>MEDIA</h2>
                <a href="/ff-logo.zip" download target="_blank">Logo</a><br />
                <a href="/image/FVCKINGFOOS-IMAGES-2023.zip">Afbeeldingen</a><br />
                <a href="https://youtu.be/1NDSZte0IMs?si=ztVxYwbunmQ6YhfN">YouTube promo 2023</a>
            </div>

            <div className={"divider"}/>

            <div className={"paragraph paragraph--content paragraph--medium"}>
                <h2>PROMO TEKST:</h2>

                Een avond met The Fucking Foo’s is hetzelfde als gelanceerd worden in een oude Sojoez-1 raket uit de jaren '60: Je weet dat het een ervaring is waar je jaren later nog over vertelt op de verjaardag van je kleinkinderen, maar tijdens de trip hou je je vast aan alles wat je dierbaar is terwijl het universum langs je oren suist. Alle hoogtepunten komen voorbij en je hebt nauwelijks tijd om adem te halen. En als je 's-avonds laat totaal uitgeput en voldaan in bed ligt mompel je in de verte de melodie van "Everlong"...
            </div>
      </>
    );
}

export default Venue;
