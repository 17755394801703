import React, {useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';


const MobileMenu = () => {
    const [isActive, setActive] = useState(false);

    const handleToggle = (e) => {
        setActive(!isActive);
        
    }

    return (
        <>
            <div className={isActive ? "menu-toggle active" : "menu-toggle"} onClick={handleToggle}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            
            <div className={isActive ? "mobile-menu active" : "mobile-menu"}>
                <ul>
                    <li>
                        <Link to="/#tour" onClick={handleToggle}>TOUR</Link>
                    </li>
                    <li>
                    <Link to="#media" onClick={handleToggle}>MEDIA</Link>
                    </li>
                    <li>
                    <Link to="/contact" onClick={handleToggle}>CONTACT</Link>
                    </li>
                </ul>
                
            </div>
        </>
    );
}

export default MobileMenu;